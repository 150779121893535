import { API } from "aws-amplify";

export async function getLeadDetails(clientId, createdAtStart, createdAtEnd) {
  try {
    const response = await API.get("leadDataAggregator", `/leads/clients`, {
      queryStringParameters: {
        clientId: clientId,
        createdAtStart: createdAtStart,
        createdAtEnd: createdAtEnd,
      },
    });
    return response;
  } catch (error) {
    console.error("Error updating tenant:", error.message);
    throw error;
  }
}
