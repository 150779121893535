import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLeadDetails } from 'services/leadDataAggregator/app';

export const fetchLeadAnalytics = createAsyncThunk(
  'leadMetrics/fetchAnalytics',
  async ({ clientId, createdAtStart, createdAtEnd }, { rejectWithValue }) => {
    try {
      const response = await getLeadDetails(clientId, createdAtStart, createdAtEnd);
      return response;
    } catch (error) {
      return rejectWithValue(error.message || `Failed to fetch lead analytics: ${JSON.stringify(error)}`);
    }
  }
);

const leadAnalyticsSlice = createSlice({
  name: 'leadMetrics',
  initialState: {
    loading: false,
    error: null,
    analyticsData: null,
  },
  reducers: {
    resetAnalytics: (state) => {
      state.analyticsData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadAnalytics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeadAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.analyticsData = action.payload;
      })
      .addCase(fetchLeadAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAnalytics } = leadAnalyticsSlice.actions;
export default leadAnalyticsSlice.reducer;
