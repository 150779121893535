import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const CapacityThrottle = Loadable(lazy(() => import('pages/ClientPortal/CapacityThrottle.jsx')));
const Exports = Loadable(lazy(() => import('pages/ClientPortal/Exports.jsx')));
const Imports = Loadable(lazy(() => import('pages/ClientPortal/Imports.jsx')));
const NFT = Loadable(lazy(() => import('pages/extra-pages/PageTemplate.jsx')));
const NFT2 = Loadable(lazy(() => import('pages/extra-pages/PageTemplate2.jsx')));

const Dashboard = Loadable(lazy(() => import('pages/ClientPortal/Dashboard.jsx')));
const ClientMappingsBU = Loadable(lazy(() => import('pages/MappingsBU.jsx')));
const ClientMappingsCampaign = Loadable(lazy(() => import('pages/MappingsCampaign.jsx')));
const ClientMappingsChannel = Loadable(lazy(() => import('pages/MappingsChannel.jsx')));
const ClientProfile = Loadable(lazy(() => import('pages/ClientPortal/Client')));
const Users = Loadable(lazy(() => import('pages/Users.jsx')));
const User = Loadable(lazy(() => import('pages/User.jsx')));
const UserPersonal = Loadable(lazy(() => import('sections/account/TabPersonal')));
const BidderCampaigns = Loadable(lazy(() => import('sections/account/TabBidderCampaigns')));

const BUCapacityReportDashboard = Loadable(lazy(() => import('pages/dashboards/buCapacityReportDashboard')));

const MarketingOverviewDashboard = Loadable(lazy(() => import('pages/dashboards/marketingOverviewDashboard')));

const GoogleAdsDashboard = Loadable(lazy(() => import('pages/dashboards/googleAdsDashboard')));

const GoogleLSADashboard = Loadable(lazy(() => import('pages/dashboards/googleLSADashboard')));

const BingAdsDashboard = Loadable(lazy(() => import('pages/dashboards/bingAdsDashboard')));

const FacebookAdsDashboard = Loadable(lazy(() => import('pages/dashboards/facebookAdsDashboard')));

const LocationsDashboard = Loadable(lazy(() => import('pages/dashboards/locationsDashboard')));

const BUCapacityThrottleDashboard = Loadable(lazy(() => import('pages/dashboards/buCapacityThrottleDashboardNew')));

const DiagnosticDashboard = Loadable(lazy(() => import('pages/dashboards/diagnosticDashboard')));
const BUDiagnosticDashboard = Loadable(lazy(() => import('../pages/dashboards/BUDiagnosticDashboard')));
const LeadAttribution = Loadable(lazy(() => import('pages/dashboards/leadAttribution')));
const AttrByFunnel = Loadable(lazy(() => import('pages/dashboards/attrByFunnel')));
const AttrByFunnelBU = Loadable(lazy(() => import('pages/dashboards/attrByFunnelBU')));

const Clients = Loadable(lazy(() => import('pages/Clients.jsx')));
const Contacts = Loadable(lazy(() => import('pages/Contacts.jsx')));
const Contact = Loadable(lazy(() => import('pages/Contact.jsx')));
const ContactsNew = Loadable(lazy(() => import('pages/ContactsNew.jsx')));
const ContactNew = Loadable(lazy(() => import('pages/ContactNew.jsx')));
const Locations = Loadable(lazy(() => import('pages/Locations')));
const Location = Loadable(lazy(() => import('pages/Location')));
// const Leads = Loadable(lazy(() => import('pages/Leads')));
const LeadsTable = Loadable(lazy(() => import('pages/LeadsTable')));
const Lead = Loadable(lazy(() => import('pages/Lead')));
const Jobs = Loadable(lazy(() => import('pages/Jobs')));
const Job = Loadable(lazy(() => import('pages/Job')));
const Invoices = Loadable(lazy(() => import('pages/Invoices')));
const Invoice = Loadable(lazy(() => import('pages/Invoice')));
const BudgetTrackerDash = Loadable(lazy(() => import('pages/BudgetTrackerDash')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const Error = Loadable(lazy(() => import('pages/maintenance/404')));
const Tenants = Loadable(lazy(() => import('pages/AdminPortal/Tenants.jsx')));
const Tenant = Loadable(lazy(() => import('pages/AdminPortal/Tenant')));
const AdminUsers = Loadable(lazy(() => import('pages/AdminPortal/AdminUsers')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));

const Integrations = Loadable(lazy(() => import('pages/ClientPortal/Integrations')));

// ==============================|| MAIN ROUTING ||============================== //

const AllRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <DiagnosticDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'sample-page',
          element: <SamplePage />,
          types: 'TENANT',
        },
        {
          path: 'integrations',
          element: <Integrations />,
          types: 'TENANT',
        },
        {
          path: 'integrations/:property',
          element: <Integrations />,
          types: 'TENANT',
        },
        {
          path: 'capacitythrottle',
          element: <CapacityThrottle />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/bucapacityreportdashboard',
          element: <BUCapacityReportDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/marketingoverview',
          element: <MarketingOverviewDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/googleads',
          element: <GoogleAdsDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/googlelsaads',
          element: <GoogleLSADashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/microsoftads',
          element: <BingAdsDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/facebookads',
          element: <FacebookAdsDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/locationsreport',
          element: <LocationsDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/capacitythrottle',
          element: <BUCapacityThrottleDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/funneldiagnostics',
          element: <DiagnosticDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/leadattribution',
          element: <LeadAttribution />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/revenueattribution',
          element: <AttrByFunnel />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/attrByFunnel/:businessUnitID',
          element: <AttrByFunnelBU />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/diagnosticdashboard/:businessUnitID',
          element: <BUDiagnosticDashboard />,
          types: 'CLIENT',
        },
        {
          path: 'users',
          element: <Users />,
          types: 'TENANT',
        },
        {
          path: 'users/:usersID',
          element: <User />,
          types: 'TENANT',
        },
        {
          path: 'users/:usersID/personal',
          element: <UserPersonal />,
          types: 'TENANT',
        },
        {
          path: 'clients/:clientsID',
          element: <ClientProfile />,
          types: 'TENANT',
        },
        {
          path: 'clients/:clientsID/biddercampaigns',
          element: <BidderCampaigns />,
          types: 'TENANT',
        },
        {
          path: 'clients/:clientsID/mappings/businessunits',
          element: <ClientMappingsBU />,
          types: 'TENANT',
        },
        {
          path: 'clients/:clientsID/mappings/campaigns',
          element: <ClientMappingsCampaign />,
          types: 'TENANT',
        },
        {
          path: 'clients/:clientsID/mappings/channels',
          element: <ClientMappingsChannel />,
          types: 'TENANT',
        },
        // {
        //   path: "settings/:clientsID",
        //   element: <ClientProfile />,
        //   types: "CLIENT",
        // },
        {
          path: 'clients',
          element: <Clients />,
          types: 'TENANT',
        },
        {
          path: 'old/contacts',
          element: <Contacts />,
          types: 'CLIENT',
        },
        {
          path: 'old/contacts/:contactID',
          element: <Contact />,
          types: 'CLIENT',
        },
        {
          path: 'contacts',
          element: <ContactsNew />,
          types: 'CLIENT',
        },
        {
          path: 'contacts/:contactID',
          element: <ContactNew />,
          types: 'CLIENT',
        },
        {
          path: 'locations',
          element: <Locations />,
          types: 'CLIENT',
        },
        {
          path: 'locations/:locationID',
          element: <Location />,
          types: 'CLIENT',
        },
        {
          path: 'leads',
          element: <LeadsTable />,
          types: 'CLIENT',
        },
        {
          path: 'leads/:leadID',
          element: <Lead />,
          types: 'CLIENT',
        },
        {
          path: 'jobs',
          element: <Jobs />,
          types: 'CLIENT',
        },
        {
          path: 'jobs/:jobID',
          element: <Job />,
          types: 'CLIENT',
        },
        {
          path: 'invoices',
          element: <Invoices />,
          types: 'CLIENT',
        },
        {
          path: 'invoices/:invoiceID',
          element: <Invoice />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/:dashboardsID',
          element: <Dashboard />,
          types: 'CLIENT',
        },
        {
          path: 'dashboard/budgettracker',
          element: <BudgetTrackerDash />,
          types: 'CLIENT',
        },
        {
          path: 'export/:clientsID',
          element: <Exports />,
          types: 'CLIENT',
        },
        {
          path: 'import/:clientsID',
          element: <Imports />,
          types: 'CLIENT',
        },
        {
          path: 'test/client/:clientsID',
          element: <NFT />,
          types: 'TENANT',
        },
        {
          path: 'test/user/:usersID',
          element: <NFT2 />,
          types: 'TENANT',
        },
        {
          path: 'admin/tenants',
          element: <Tenants />,
          types: 'ADMIN',
        },
        {
          path: 'admin/users',
          element: <AdminUsers />,
          types: 'ADMIN',
        },
        {
          path: 'admin/tenants/:id',
          element: <Tenant />,
          types: 'ADMIN',
        },
      ],
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />,
        },
        {
          path: '500',
          element: <MaintenanceError500 />,
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />,
        },
      ],
    },
    {
      path: '*',
      element: <Error />,
    },
  ],
};

export default AllRoutes;
