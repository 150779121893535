// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import leadsReducer from './leadsSlice';
import leadAnalyticsReducer from './leadAnalyticsSlice';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  leads: leadsReducer,
  leadAnalytics: leadAnalyticsReducer,
});

export default reducers;
